div.navbar {
  margin: auto;
  margin-bottom: 1%;
  width: 90%;
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.navbar > img {
  width: 8%;
  align-self: center;
  margin-bottom: 10px;
}

.navbar.sm {
  width: 100%;
  background-color: #C81112;
  position: sticky;
  top: 0;
  border: none;
  flex-direction: row;
  justify-content: space-between;
  z-index: 5;
  align-items: flex-start;
}

.navbar.sm > img{
  width: 15%;
  height: 15%;
  align-self: flex-start;
}

.tabbar{
  height: 30%;
  width: 80%;
  background-color: #C81112;
  display: flex;
  align-self: center;
  justify-content: center;
}

.tabbuttons{
  height: 98%;
  width: 70%;
  background-color: #0E203D;
  display: flex;
  justify-content: space-evenly;
  border-color: #C81112;
  border-style: solid;
  border-width: 5px;
}

.tabbuttons > button{
  width: 20%;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #C81112;
  color: white;
  border: none;
  border-left: 2px solid #0E203D;
}

.tabbuttons > button> a:link,a:visited{
  color: white;
  text-decoration: none;
  }

  .hamburger-react {
  margin-bottom: 10%;
}

.tabbar.sm {
  justify-content: flex-end;
  box-sizing: border-box;
  padding-top: 2%;
  align-self: flex-start;
}

.tabbuttons.sm {
  flex-direction: column;
  justify-content: center;
  width: 20%;
  align-items: center;
  align-self: flex-start;
  margin-top: 1%;
}

.tabbuttons.sm > button {
  width: 90%;
  border-left: none;
  margin-bottom: 5px;
  text-align: center;
  font-size: larger;
}