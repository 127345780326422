.about-container {
  display: flex;
  justify-content: space-around;
  margin: auto;
  margin-top: 5vh;
  width: 60%;
  height: 80vh;
}

.about-header {
  font-weight: bold;
}

.about-container.sm {
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100%;
  padding-bottom: 20px;
}

.buttonFormat {
  width: 40%;
  flex-direction: column;
  align-items: center;
}

.buttonFormat> .FormButton {
  cursor: pointer;
  font-size:x-large;
  font-family: 'Press Start 2P', cursive;
  margin: .5vw;
  padding: 10px;
  margin-top: 5vh;
  margin-bottom: 5vh;
  border-width: 5px;
  border-style: solid;
  border-image: linear-gradient(150deg, #C81112 0%, rgb(255, 4, 0) 20%, rgb(255, 52, 25) 80%, rgba(255, 167, 145) 100%);
  background: linear-gradient(150deg, #C81112 0%, rgba(255, 4, 0) 20%, rgba(255, 52, 25) 80%, rgb(255, 167, 145) 100%);
  background-color: transparent;
  border-radius: 5px;
  color: white;
  width: 95%;
  height: 10%;
}
.FormButton> a:link,a:visited{
color: white;
text-decoration: none;
}
.about-container>img {
  width: 25%;
  height: 50vh;
  border-radius: 25px;
}