  .SignButton {
    cursor: pointer;
    font-size:  large;
    font-family: 'Press Start 2P', cursive;
    border-width: 5px;
    border-style: solid;
    border-image: linear-gradient(150deg, #C81112 0%, rgb(255, 4, 0) 20%, rgb(255, 52, 25) 80%, rgba(255, 167, 145) 100%);
    background: linear-gradient(150deg, #C81112 0%, rgba(255, 4, 0) 20%, rgba(255, 52, 25) 80%, rgb(255, 167, 145) 100%);
    background-color: transparent;
    border-radius: 5px;
    color: white;
    width: 40%;
    max-width: 20vw;
    height: 20%;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .SignButton>a:link,
  a:visited {
    color: white;
    text-decoration: none;
  }

  .Welcomediv {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 45%;
    box-sizing: border-box;
    padding-bottom: 15%;
    width: 80%;
    height: 70%;
  }

  .Welcomediv>button {
    opacity: 1;
  }

  .welcomeFormat {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
    width: 90%;
    height: 100vh;
  }

  .welcomeFormat>img {
    padding-top: 10vh;
    max-height: 40vh;
    width: 50%;
  }