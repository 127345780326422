.ContactFormat {
  height: 80vh;
}

div.contact {
  margin: auto;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 3%;
}
.contact >p{
font-weight: bold;
}
.contact-card {
  width: 40%;
}
.contact-card>p {
  text-align: center;
}
.form {  
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1%;
}

.form-group > label {
  width: 15%;
  font-weight: bold;
}

.form-group > input {
  width: 80%;
  height: 30%;
}