.abtUs {
    display: flex;
    text-align: left;
    justify-content: space-evenly;
    flex-direction: column;
    margin: auto;
    width: 70%;
    height: auto;
    padding-bottom: 1%;
}

.abtPhotos {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.abtPhotos>img {
    width: 33%;
    border-radius: 25px;
}
.addproj {
    display: flex;
    justify-content: space-around;
    text-align: center;
    margin: auto;
    margin-top: 1%;
    width: 30%;
    height: auto;
}
.bios {
    display: flex;
    text-align: left;
    flex-direction: column;
    width: 100%;
}
.bios>h3 {
    color: #C81112;
}


.summary {
    flex-direction: column;
    width: 90%;
}

